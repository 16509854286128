export const tasteClassification = {
    A_1: {
        class: "A_1",
        name: "甘舌-A",
        characteristics: [
            "甘味を好み、苦味と酸味はやや苦手とする",
            "ピュアな味覚を持ったタイプ",
        ],
        sake_flavors: ["中口", "やや甘口", "甘口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
        ],
        recommended_dishes_and_snacks: [
            "すき焼き",
            "牛すじ煮込み",
            "だし巻き卵",
            "焼き鳥（たれ）",
            "大学いも",
        ],
        comments: [
            "子供の頃から好き嫌いが変わっていない",
            "I LOVE ハンバーグ！",
            "お子様ランチが羨ましい",
            "外食でいつも同じものを注文しがち",
        ],
    },
    A_2: {
        class: "A_2",
        name: "甘舌-B",
        characteristics: [
            "全体的にバランスがよく、薄味を好む",
            "未体験の味にも挑戦するタイプ",
        ],
        sake_flavors: ["中口", "やや甘口", "甘口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
        ],
        recommended_dishes_and_snacks: [
            "すき焼き",
            "牛すじ煮込み",
            "だし巻き卵",
            "焼き鳥（たれ）",
            "大学いも",
        ],
        comments: [
            "好き嫌いは少ない方",
            "味付けは薄味が好き♥",
            "野菜は茹でてシンプルに",
            "ビュッフェは全て制覇する派",
        ],
    },
    A_3: {
        class: "A_3",
        name: "甘舌-C",
        characteristics: [
            "甘味とうま味を好み、刺激が強いものは好まない",
            "食の欲求に素直なタイプ",
        ],
        sake_flavors: ["中口", "やや甘口", "甘口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
        ],
        recommended_dishes_and_snacks: [
            "すき焼き",
            "牛すじ煮込み",
            "だし巻き卵",
            "焼き鳥（たれ）",
            "大学いも",
        ],
        comments: [
            "好き嫌いがはっきりしている",
            "新作カフェドリンクは必ず試す",
            "流行りのスイーツはとりあえずチェック",
            "ハンバーグと唐揚げLOVE",
        ],
    },
    A_4: {
        class: "A_4",
        name: "甘舌-D",
        characteristics: [
            "甘味とうま味を好む",
            "刺激が強いものは好まない",
            "好き嫌いがはっきりしている",
        ],
        sake_flavors: ["中口", "やや甘口", "甘口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
        ],
        recommended_dishes_and_snacks: [
            "すき焼き",
            "牛すじ煮込み",
            "だし巻き卵",
            "焼き鳥（たれ）",
            "大学いも",
        ],
        comments: [
            "甘い煮物が好き",
            "飲み物にシロップは必須！",
            "味噌汁よりも吸い物が好き",
            "いつも同じものを食べがち",
        ],
    },
    U_1: {
        class: "U_1",
        name: "うま舌-A",
        characteristics: [
            "薄味で、うま味と苦味を好む",
            "素材の味わいを重視するタイプ",
        ],
        sake_flavors: ["薄やや辛口", "中口", "やや甘口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
            "純米大吟醸原酒",
        ],
        recommended_dishes_and_snacks: [
            "肉じゃが",
            "おでん",
            "あさりバター",
            "茶わん蒸し",
            "ほうれん草のお浸し",
        ],
        comments: [
            "好き嫌いは少ない方",
            "「だし」にはこだわる派",
            "野菜は茹でてシンプルに",
            "おにぎりは梅干しより昆布派",
        ],
    },
    U_2: {
        class: "U_2",
        name: "うま舌-B",
        characteristics: [
            "薄味でうま味を好む",
            "しょっぱいものや苦味はやや苦手",
        ],
        sake_flavors: ["やや辛口", "中口", "やや甘口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
            "純米大吟醸原酒",
        ],
        recommended_dishes_and_snacks: [
            "肉じゃが",
            "おでん",
            "あさりバター",
            "茶わん蒸し",
            "ほうれん草のお浸し",
        ],
        comments: [
            "「だし」にはこだわる派",
            "野菜は茹でてシンプルに",
            "刺激的な味付けは苦手",
            "味噌汁よりも吸い物が好き",
        ],
    },
    U_3: {
        class: "U_3",
        name: "うま舌-C",
        characteristics: [
            "薄味で酸味のある味を好む",
            "苦味に敏感でやや苦手とし、繊細な味覚を持つタイプ",
        ],
        sake_flavors: ["やや辛口", "中口", "やや甘口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
            "純米大吟醸原酒",
        ],
        recommended_dishes_and_snacks: [
            "肉じゃが",
            "おでん",
            "あさりバター",
            "茶わん蒸し",
            "ほうれん草のお浸し",
        ],
        comments: [
            "野菜は茹でてシンプルに",
            "フォーが好きだけどパクチーは苦手",
            "ポン酢は欠かさず常備",
            "外食より自炊派",
        ],
    },
    U_4: {
        class: "U_4",
        name: "うま舌-D",
        characteristics: [
            "薄味で、うま味があるものを好む",
            "すっきりした柑橘系の酸味が好きなタイプ",
        ],
        sake_flavors: ["薄やや辛口", "中口", "やや甘口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "純米大吟醸酒",
            "本醸造酒",
            "純米原酒",
            "純米大吟醸原酒",
        ],
        recommended_dishes_and_snacks: [
            "肉じゃが",
            "おでん",
            "あさりバター",
            "茶わん蒸し",
            "ほうれん草のお浸し",
        ],
        comments: [
            "「だし」にはこだわる派",
            "酢や柑橘類を使った減塩調理が得意",
            "アジア系料理が好き",
            "ポン酢は欠かさず常備",
        ],
    },
    C_1: {
        class: "C_1",
        name: "こども舌-A",
        characteristics: ["バランスが取れている", "やや苦味が苦手"],
        sake_flavors: ["中口", "やや甘口", "甘口"],
        sake_types: ["純米酒", "純米吟醸酒", "純米大吟醸酒"],
        recommended_dishes_and_snacks: [
            "ハンバーグ",
            "肉団子",
            "コロッケ",
            "ポテトサラダ",
            "枝豆",
        ],
        comments: [
            "I LOVE 唐揚",
            "サラダはドレッシングが命",
            "給食は絶対に残さない",
            "薬味は少量でOK",
        ],
    },
    C_2: {
        class: "C_2",
        name: "こども舌-B",
        characteristics: [
            "甘味とうま味を好み苦味が苦手",
            "好き嫌いがはっきりしている",
        ],
        sake_flavors: ["中口", "やや甘口", "甘口"],
        sake_types: ["純米酒", "純米吟醸酒", "純米大吟醸酒"],
        recommended_dishes_and_snacks: [
            "ハンバーグ",
            "肉団子",
            "コロッケ",
            "ポテトサラダ",
            "枝豆",
        ],
        comments: [
            "I LOVE オムライス",
            "ビターチョコより断然ミルクチョコ",
            "薬味はお断り！",
            "苦手な食べものは残しがち",
        ],
    },
    C_3: {
        class: "C_3",
        name: "こども舌-C",
        characteristics: [
            "塩味と甘味があるしっかりとした味を好む",
            "苦味が苦手で酸味もやや苦手",
        ],
        sake_flavors: ["中口", "やや甘口", "甘口"],
        sake_types: ["純米酒", "純米吟醸酒", "純米大吟醸酒"],
        recommended_dishes_and_snacks: [
            "ハンバーグ",
            "肉団子",
            "コロッケ",
            "ポテトサラダ",
            "枝豆",
        ],
        comments: [
            "I LOVE ハンバーガー",
            "お子様ランチが食べたい大人",
            "乾杯はビールより甘めのサワー",
            "食べもので冒険はしない主義",
        ],
    },
    B_1: {
        class: "B_1",
        name: "バランス舌-A",
        characteristics: [
            "日本人の平均的な食卓の味わいを好む",
            "好き嫌いが少ない",
        ],
        sake_flavors: ["やや辛口", "中口", "やや甘口"],
        sake_types: ["純米酒", "純米大吟醸酒", "純米吟醸酒"],
        recommended_dishes_and_snacks: [
            "ローストビーフ",
            "鰹のたたき",
            "ポテトサラダ",
            "卯の花",
            "切り干し大根",
        ],
        comments: [
            "1日3食ちゃんと食べる",
            "家のご飯が大好き",
            "手料理にこだわりがある",
            "売れ筋商品を選びがち",
        ],
    },
    B_2: {
        class: "B_2",
        name: "バランス舌-B",
        characteristics: [
            "うま味の許容が少しだけ低い",
            "素材を活かした味を好むタイプ",
        ],
        sake_flavors: ["やや辛口", "中口", "やや甘口"],
        sake_types: ["純米酒", "純米大吟醸酒", "純米吟醸酒"],
        recommended_dishes_and_snacks: [
            "ローストビーフ",
            "鰹のたたき",
            "ポテトサラダ",
            "卯の花",
            "切り干し大根",
        ],
        comments: [
            "和食より洋食派",
            "手料理には妥協がある",
            "ステーキは塩コショウでシンプルに",
            "売れ筋商品を選びがち",
        ],
    },
    B_3: {
        class: "B_3",
        name: "バランス舌-C",
        characteristics: [
            "苦味、酸味、うま味のある複雑な味を好む",
            "塩分にも敏感で、甘みもほどほどの大人の嗜好を持つタイプ",
        ],
        sake_flavors: ["やや辛口", "中口", "やや甘口"],
        sake_types: ["純米酒", "純米大吟醸酒", "純米吟醸酒"],
        recommended_dishes_and_snacks: [
            "ローストビーフ",
            "鰹のたたき",
            "ポテトサラダ",
            "卯の花",
            "切り干し大根",
        ],
        comments: [
            "とりあえずビール！",
            "昔ながらの食品が好き",
            "美味しいお店を知っている",
            "珍しい料理にも挑戦する派",
        ],
    },
    S_1: {
        class: "S_1",
        name: "とんがり舌-A",
        characteristics: [
            "塩味と酸味の強い味を好む",
            "味わいのメリハリがしっかりしたものを好むタイプ",
        ],
        sake_flavors: ["辛口", "やや辛口", "中口"],
        sake_types: ["純米酒", "純米吟醸酒", "本醸造酒", "純米原酒"],
        recommended_dishes_and_snacks: [
            "アジの南蛮漬け",
            "焼き鳥（塩）",
            "餃子",
            "ニラ玉",
            "いかの塩辛",
        ],
        comments: [
            "酢豚が好き♥",
            "ラーメンにお酢を入れる派",
            "漬物に醤油はつきもの",
            "甘い煮物は苦手",
        ],
    },
    S_2: {
        class: "S_2",
        name: "とんがり舌-B",
        characteristics: [
            "塩味と酸味の強いはっきり味を好む",
            "苦いものやうま味が強いものはあまり得意ではない",
        ],
        sake_flavors: ["辛口", "やや辛口", "中口", "やや甘口"],
        sake_types: ["純米酒", "純米吟醸酒", "本醸造酒", "純米原酒"],
        recommended_dishes_and_snacks: [
            "アジの南蛮漬け",
            "焼き鳥（塩）",
            "餃子",
            "ニラ玉",
            "いかの塩辛",
        ],
        comments: [
            "乾杯はレモン酎ハイ！",
            "おつまみLOVE",
            "おにぎりは梅干し一択",
            "何にでもレモンをかけがち",
        ],
    },
    S_3: {
        class: "S_3",
        name: "とんがり舌-C",
        characteristics: [
            "塩気と酸味の強いしっかりした味わいを好む",
            "苦いものはあまり得意ではない",
        ],
        sake_flavors: ["辛口", "やや辛口", "中口", "やや甘口"],
        sake_types: ["純米酒", "純米吟醸酒", "本醸造酒", "純米原酒"],
        recommended_dishes_and_snacks: [
            "アジの南蛮漬け",
            "焼き鳥（塩）",
            "餃子",
            "ニラ玉",
            "いかの塩辛",
        ],
        comments: [
            "ファーストフードが好き",
            "香辛料が苦手",
            "ビールより甘めのお酒♥",
            "チョコよりポテチ派",
        ],
    },
    N_1: {
        class: "N_1",
        name: "おやじ舌-A",
        characteristics: [
            "苦い味つけの食事も得意",
            "全体的にしっかりとした味付けを好む",
        ],
        sake_flavors: ["辛口", "やや辛口", "中口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "吟醸酒",
            "本醸造酒",
            "酒粕焼酎",
        ],
        recommended_dishes_and_snacks: [
            "もつ煮",
            "きんぴらごぼう",
            "麻婆豆腐",
            "ビーフシチュー",
            "焼ビーフン炒め",
        ],
        comments: [
            "乾杯はビールで！",
            "美味しいお店知っている",
            "甘い煮物は苦手",
            "鍋料理の回数が多め",
        ],
    },
    N_2: {
        class: "N_2",
        name: "おやじ舌-B",
        characteristics: [
            "苦味と塩味が強い濃い味付けを好む",
            "お酒や味覚体験が豊富",
        ],
        sake_flavors: ["辛口", "やや辛口", "中口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "吟醸酒",
            "本醸造酒",
            "酒粕焼酎",
        ],
        recommended_dishes_and_snacks: [
            "もつ煮",
            "きんぴらごぼう",
            "麻婆豆腐",
            "ビーフシチュー",
            "焼ビーフン炒め",
        ],
        comments: [
            "乾杯はビールで！",
            "大人になってから好みが変わった",
            "濃い味が好きでお酒も好き",
            "ピザ・寿司が好き",
        ],
    },
    N_3: {
        class: "N_3",
        name: "おやじ舌-C",
        characteristics: [
            "苦味と酸味が強く刺激的な味付けを好む",
            "食経験が豊か",
        ],
        sake_flavors: ["辛口", "やや辛口", "中口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "吟醸酒",
            "本醸造酒",
            "酒粕焼酎",
        ],
        recommended_dishes_and_snacks: [
            "もつ煮",
            "きんぴらごぼう",
            "麻婆豆腐",
            "ビーフシチュー",
            "焼ビーフン炒め",
        ],
        comments: [
            "乾杯はビールで！",
            "つまみを愛する",
            "香辛料を多めにかけがち",
            "珍しい料理にも挑戦する派",
        ],
    },
    N_4: {
        class: "N_4",
        name: "おやじ舌-D",
        characteristics: [
            "苦味、酸味、塩味が強い濃い味付けを好む",
            "生活習慣病には要注意",
        ],
        sake_flavors: ["辛口", "やや辛口", "中口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "吟醸酒",
            "本醸造酒",
            "酒粕焼酎",
        ],
        recommended_dishes_and_snacks: [
            "もつ煮",
            "きんぴらごぼう",
            "麻婆豆腐",
            "ビーフシチュー",
            "焼ビーフン炒め",
        ],
        comments: [
            "とりあえずビール！",
            "こってり味のラーメンが好き",
            "お酒は強い方",
            "チョコよりポテチ派",
        ],
    },
    N_5: {
        class: "N_5",
        name: "おやじ舌-E",
        characteristics: [
            "塩気の強い濃い味付けを好む",
            "塩分摂取量には要注意",
        ],
        sake_flavors: ["辛口", "やや辛口", "中口"],
        sake_types: [
            "純米酒",
            "純米吟醸酒",
            "吟醸酒",
            "本醸造酒",
            "酒粕焼酎",
        ],
        recommended_dishes_and_snacks: [
            "もつ煮",
            "きんぴらごぼう",
            "麻婆豆腐",
            "ビーフシチュー",
            "焼ビーフン炒め",
        ],
        comments: [
            "とりあえずビール！",
            "味の濃いものが好き",
            "チョコよりポテチ派",
            "漬物に醤油を掛けがち",
        ],
    },
};