<template>
  <v-container class="text-center">
    <h1>Oops! Invalid Request</h1>
    <p>
      The <strong>recommend_count</strong> must be between
      <strong>3 and 5</strong>. Please enter a valid number and try again.
    </p>
    <p>Redirecting to home page...</p>
    <v-btn @click="goHome" color="primary">Go Home</v-btn>
  </v-container>
</template>

<script>
export default {
  name: "InvalidQueryParam",
  data() {
    return {
    };
  },
  methods: {
    goHome() {
      let count = null;
      const savedCount = localStorage.getItem("lastRecommendCount");

      if (savedCount !== null) {
        count = savedCount;
      }
      // Remove localStorage data before redirecting
      localStorage.removeItem("lastRecommendCount");

      // Redirect to the start url
      this.$router.replace(`/?recommend_count=${count}`);
    },
  },
};
</script>

<style scoped>
h1 {
  color: red;
}
</style>
