<template>
  <v-dialog
    :value="isOpenDialog"
    @input="updateDialog"
    max-width="700px"
    scrollable
  >
    <v-card>
      <v-card-actions class="pt-4 pb-0">
        <v-spacer></v-spacer>
        <v-icon @click="closeProductDetailsDialog" class="cursor-pointer"
          >mdi-close</v-icon
        >
      </v-card-actions>
      <div
        class="flex-shrink-0"
        :style="{
          height: $vuetify.breakpoint.xs ? '10.5rem' : '15.625rem',
          padding: '0px 24px 10px 24px',
        }"
      >
        <v-img
          :src="selectedProduct.image"
          :alt="selectedProduct.name"
          height="100%"
          contain
        ></v-img>
      </div>
      <v-card-title>
        <span class="text-xs-subtitle-1">{{ selectedProduct.name }}</span>
      </v-card-title>

      <v-card-text>
        <div class="text-h6 mb-2">
          {{ `¥${selectedProduct.price}` }}
        </div>
        <div class="mb-2">
          <p class="mb-0">
            <span class="font-weight-bold">味の種類:</span
            >{{ ` ${selectedProduct.tasteType}` }}
          </p>
          <p class="mb-0">
            <!-- <span class="font-weight-bold">製造区分:</span> -->
            <span class="font-weight-bold">酒の種類:</span>
            {{
              ` ${
                selectedProduct.ingredientType.length > 0
                  ? selectedProduct.ingredientType
                  : "Unknown"
              }`.trim()
            }}
          </p>
          <p class="mb-0">
            <span class="font-weight-bold">容量:</span>
            {{
              ` ${
                selectedProduct.volume.length > 0
                  ? selectedProduct.volume
                  : "Unknown"
              }`.trim()
            }}
          </p>
          <p class="mb-0">
            <span class="font-weight-bold">オススメの飲み方:</span>
            {{ `${selectedProduct.recommendedWayToDrink}` }}
          </p>
        </div>

        <div class="text-body-2 text-justify mb-2">
          <span class="font-weight-bold">製品説明:</span>
          {{ selectedProduct.description }}
        </div>

        <div v-if="childItemSlides.length > 0">
          <v-divider></v-divider>

          <div class="mt-2">
            <p class="font-weight-bold text-center">その他の容量の詳細</p>
          </div>
          <!-- {{ selectedProduct?.childItems }} -->
          <div class="d-flex justify-center align-center">
            <div>
              <v-carousel
                :cycle="cycle"
                hide-delimiter-background
                show-arrows-on-hover
                delimiter-icon="mdi-minus"
                dark
                height="300"
                width="500"
              >
                <v-carousel-item v-for="(slide, i) in childItemSlides" :key="i">
                  <v-img
                    class="custom-img"
                    :src="slide.images"
                    :alt="slide.商品名"
                    contain
                  >
                  </v-img>
                  <v-row justify="center">
                    <v-col cols="12" class="text-center font-weight-bold mt-4">
                      <p class="mb-0">
                        {{ `${slide.商品名} (${slide.容量})` }}
                      </p>
                      <p class="mb-0">
                        {{ `¥${formatPrice(slide.参考価格)}` }}
                      </p>
                    </v-col>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <div>
          <div class="mt-2">
            <p class="font-weight-bold text-center">フードペアリング</p>
          </div>

          <div>
            <div>
              <p class="mb-0">
                <span class="font-weight-bold">和食:</span>
              </p>
              <ul>
                <li
                  v-for="(
                    item, index
                  ) in selectedProduct.recommendedJapaneseFood"
                  :key="index"
                >
                  {{
                    `${
                      selectedProduct.recommendedJapaneseFood.length > 0
                        ? item
                        : "Unknown"
                    }`
                  }}
                </li>
              </ul>
            </div>
            <div>
              <p class="mb-0">
                <span class="font-weight-bold">洋食:</span>
              </p>
              <ul>
                <li
                  v-for="(
                    item, index
                  ) in selectedProduct.recommendedWesternFood"
                  :key="index"
                >
                  {{
                    `${
                      selectedProduct.recommendedWesternFood.length > 0
                        ? item
                        : "Unknown"
                    }`
                  }}
                </li>
              </ul>
            </div>
            <div>
              <p class="mb-0">
                <span class="font-weight-bold">中華・エスニック:</span>
              </p>
              <ul>
                <li
                  v-for="(
                    item, index
                  ) in selectedProduct.recommendedChineseAndEthnicFood"
                  :key="index"
                >
                  {{
                    `${
                      selectedProduct.recommendedChineseAndEthnicFood.length > 0
                        ? item
                        : "Unknown"
                    }`
                  }}
                </li>
              </ul>
            </div>
            <div>
              <p class="mb-0">
                <span class="font-weight-bold">スイーツ・フルーツ:</span>
              </p>
              <ul>
                <li
                  v-for="(
                    item, index
                  ) in selectedProduct.recommendedSweetsAndFruit"
                  :key="index"
                >
                  {{
                    `${
                      selectedProduct.recommendedSweetsAndFruit.length > 0
                        ? item
                        : "Unknown"
                    }`
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProductDetails",
  computed: {
    childItemSlides() {
      return this.selectedProduct?.childItems?.length > 0
        ? this.selectedProduct.childItems
        : [];
    },
  },
  data: () => ({
    cycle: true,
  }),
  props: {
    isOpenDialog: {
      type: Boolean,
      required: true,
    },
    selectedProduct: {
      type: Object,
      required: true,
    },
  },
  methods: {
    closeProductDetailsDialog() {
      this.$emit("update:isOpenDialog", false);
    },
    updateDialog(value) {
      this.$emit("update:isOpenDialog", value);
    },
    formatPrice(price) {
      // Convert to string and split by decimal point
      const [integerPart, decimalPart] = price.toString().split(".");

      // Format the integer part with commas
      const formattedInteger = integerPart.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );

      // Return with decimal part if it exists
      if (decimalPart !== undefined) {
        return `${formattedInteger}.${decimalPart}`;
      } else {
        return formattedInteger;
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.custom-img {
  max-width: 75%;
  max-height: 70%;
  margin: auto;
}
</style>
