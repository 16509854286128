export const setRecommendationCountQueryParameter = async (context) => {
    const newValue = context.currentQueryParamValue;
    // console.log(`New Query Value: ${newValue}`);
    try {
        await context.$router.replace({
            path: context.$route.path,
            query: { ...context.$route.query, recommend_count: newValue },
        });
    } catch (err) {
        if (err.name !== "NavigationDuplicated") {
            console.error(err);
        }
    }

};