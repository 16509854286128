import Vue from 'vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'
import store from "./store/index";
import App from './App.vue';
import HomePage from './views/HomePage.vue';
import InvalidQueryParam from './views/InvalidQueryParam.vue';

Vue.config.productionTip = false
Vue.use(VueRouter)

const routes = [
  { path: '/', component: HomePage },
  { path: "/invalid-query-parameter", component: InvalidQueryParam },
]

const router = new VueRouter({
  mode: "history", // Removes hash (#) from URLs
  routes,
});

// Navigation guard to check and store valid recommend_count values
router.beforeEach((to, from, next) => {

  // If navigating to home with a recommend_count parameter
  if (to.path === '/' && to.query.recommend_count) {
    console.log("Navigation from", from.fullPath, "to", to.fullPath);
    const recommendCount = parseInt(to.query.recommend_count);

    // Check if recommend_count is valid (number and ≤ 5 or >= 3)
    if (!isNaN(recommendCount) && recommendCount >= 3 && recommendCount <= 5) {
      // Valid recommend_count, store in local storage
      localStorage.setItem('lastRecommendCount', recommendCount);
      next(() => {
      });
    } else {
      // Invalid recommend_count, redirect to error page & Do NOT overwrite the last valid recommend_count
      next('/invalid-query-parameter');
    }
  } else {
    // For all other navigation, just proceed
    next();
  }
});

new Vue({
  router,
  store,
  vuetify,

  render: h => h(App)
}).$mount('#app')
