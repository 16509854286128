import { IS_VALID_CREDENTIALS_FORM, CREDENTIALS_FORM_DATA, TOGGLE_CREDENTIALS_FORM } from "../action_types";
import { UPDATE_IS_VALID_CREDENTIALS_FORM, UPDATE_CREDENTIALS_FORM_DATA, UPDATE_TOGGLE_CREDENTIALS_FORM } from "../mutation_types";

const state = {
    isOpenCredentialsForm: false,
    isValidCredentialsForm: false,
    credentialsFromData: {
        questionnaireId: process.env.VUE_APP_QUESTIONNAIRE_ID,
        projectKey: process.env.VUE_APP_PROJECT_KEY,
        apiKey: process.env.VUE_APP_API_KEY,
        // recommendationCount: process.env.VUE_APP_RECOMMENDATION_COUNT,
    },
};
// console.log("Credentials Form State:", state);
const actions = {
    [TOGGLE_CREDENTIALS_FORM]({ commit }, { isOpen }) {
        commit(UPDATE_TOGGLE_CREDENTIALS_FORM, isOpen);
    },
    [IS_VALID_CREDENTIALS_FORM]({ commit }, { isValid }) {
        commit(UPDATE_IS_VALID_CREDENTIALS_FORM, isValid);
    },
    [CREDENTIALS_FORM_DATA]({ commit }, { data }) {
        if (data) {
            // console.log("Passed credentials action data:", data);
            commit(UPDATE_CREDENTIALS_FORM_DATA, data);
        } else {
            console.log('CREDENTIALS_FORM_DATA is Undefined');
        }
    },
};

const mutations = {
    [UPDATE_TOGGLE_CREDENTIALS_FORM](state, isOpen) {
        state.isOpenCredentialsForm = isOpen;
    },
    [UPDATE_IS_VALID_CREDENTIALS_FORM](state, isValid) {
        state.isValidCredentialsForm = isValid;
    },
    [UPDATE_CREDENTIALS_FORM_DATA](state, data) {
        if (data) {
            // console.log("Passed state credentials mutation data:", data);
            state.credentialsFromData = { ...state.credentialsFromData, ...data };
        } else {
            console.log('UPDATE_CREDENTIALS_FORM_DATA is Undefined');
        }
    },
};

const getters = {
    isOpenCredentialsForm: (state) => state.isOpenCredentialsForm,
    isValidCredentialsForm: (state) => state.isValidCredentialsForm,
    credentialsFromData: (state) => state.credentialsFromData,
};

export const credentials_form = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};